@import "tailwindcss/base";
@import "tailwindcss/components";
@import "tailwindcss/utilities";
@import url("https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap");

@import url(./styles/swiper.css);

@font-face {
  font-family: "CASpotnik";
  src: url(../public/fonts/CASpotnik-Regular.otf) format("woff"),
    url(../public/fonts/CASpotnik-Regular.otf) format("woff2"),
    url(../public/fonts/CASpotnik-Regular.otf) format("truetype"),
    url(../public/fonts/CASpotnik-Regular.otf) format("svg"),
    url(../public/fonts/CASpotnik-Regular.otf) format("embedded-opentype");
}
@font-face {
  font-family: "CASpotnik Medium";
  src: url(../public/fonts/CASpotnik-Medium.otf) format("woff"),
    url(../public/fonts/CASpotnik-Medium.otf) format("woff2"),
    url(../public/fonts/CASpotnik-Medium.otf) format("truetype"),
    url(../public/fonts/CASpotnik-Medium.otf) format("svg"),
    url(../public/fonts/CASpotnik-Medium.otf) format("embedded-opentype");
}
@font-face {
  font-family: "CASpotnik Bold";
  src: url(../public/fonts/CASpotnik-ExtraBold.otf) format("woff"),
    url(../public/fonts/CASpotnik-ExtraBold.otf) format("woff2"),
    url(../public/fonts/CASpotnik-ExtraBold.otf) format("truetype"),
    url(../public/fonts/CASpotnik-ExtraBold.otf) format("svg"),
    url(../public/fonts/CASpotnik-ExtraBold.otf) format("embedded-opentype");
}
