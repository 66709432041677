.lightTheme {
  background: #000;
}
.darkTheme {
  background: #fff;
}
.paginationBullet {
  cursor: pointer;
  display: inline-block;
  border-radius: 50%;
  margin: 0 5px;
  opacity: 0.5;
  width: 10px;
  height: 10px;
}
.paginationBulletActive {
  opacity: 1;
}
