.swiper-initialized {
  padding-bottom: 40px !important;
}

@media (max-width: 900px) {
  .swiper-initialized {
    padding-bottom: 30px !important;
  }
}

.swiper-pagination {
  bottom: 0px !important;
}
